import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapCambie,
  locationMapNewWest,
  locationMapBroadway,
  mobileMapCambie,
  mobileMapNewWest,
  mobileMapBroadway,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'E9UStmw0kdZVDzKDvHha') {
          locationLink = 'https://g.page/TonTonSushi?share'
          mapImage = locationMapCambie
          mapImageMobile = mobileMapCambie
        } else if (locationId === 'QUur6QgTAFdRHKCv2Oku') {
          locationLink = 'https://g.page/tontonsushi_newwestminster?share'
          mapImage = locationMapNewWest
          mapImageMobile = mobileMapNewWest
        } else {
          locationLink = 'https://maps.app.goo.gl/HQ1hD3tgeeYXoK438'
          mapImage = locationMapBroadway
          mapImageMobile = mobileMapBroadway
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage
                  src={mapImageMobile}
                  w="100%"
                  alt="Mainichi Sushi (Old Ton Ton) Map"
                />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink} target="_blank">
                <CFImage
                  src={mapImage}
                  w="100%"
                  alt="Mainichi Sushi (Old Ton Ton) Map"
                />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
