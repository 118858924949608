import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  promo1,
  promo2,
  promo3,
  mobilePromo1,
  mobilePromo2,
  mobilePromo3,
} from 'images'
import { dispatch } from 'store'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={mobilePromo1}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={mobilePromo2}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={mobilePromo3}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <CFImage src={promo1} w="100%" alt="Mainichi Sushi (Old Ton Ton) Map" />
        <CFImage src={promo2} w="100%" alt="Mainichi Sushi (Old Ton Ton) Map" />
        <CFImage src={promo3} w="100%" alt="Mainichi Sushi (Old Ton Ton) Map" />
      </DefaultScreen>
    </CFView>
  )
}
