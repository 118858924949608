export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1590261442/general/cflogo.png'
export const orderDeliveryButton = ''
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634679523/tonton/about.jpg'
export const aboutFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874348/samuraisushi/aboutFraser.png'
export const downloadAppButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1637278785/tonton/downloadAppButton2.png'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634690618/tonton/contactBg2.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634679653/tonton/aboutCambie.jpg'
export const foodGalleryNewWest =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1637278786/tonton/aboutNewWest2.jpg'
export const foodGalleryBroadway =
  'https://d3333g6o4t76vd.cloudfront.net/about2.webp'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634678019/tonton/hero.jpg'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874362/samuraisushi/homebg.jpg'
export const locationMapCambie =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634680704/tonton/locationMapCambie.jpg'
export const locationMapNewWest =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634680704/tonton/locationMapNewWest.jpg'
export const locationMapBroadway =
  'https://d3333g6o4t76vd.cloudfront.net/locationMap2.webp'
export const logo = 'https://d3333g6o4t76vd.cloudfront.net/logo2.webp'
export const logoTitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634678215/tonton/logoTitle.png'
export const logoWhite =
  'https://d3333g6o4t76vd.cloudfront.net/mobileLogo2.webp'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634682337/tonton/mobileAbout.jpg'
export const mobileAboutFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874379/samuraisushi/mobileAboutFraser.png'
export const mobileAbout2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874378/samuraisushi/mobileAbout2.png'
export const mobileAbout2Fraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874379/samuraisushi/mobileAbout2Fraser.png'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634683539/tonton/mainContactbg.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634680493/tonton/mobileGalleryCambie.jpg'
export const mobileGalleryNewWest =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1637278785/tonton/mobileAboutNewWest2.jpg'
export const mobileGalleryBroadway =
  'https://d3333g6o4t76vd.cloudfront.net/mobilaAbout2.webp'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634681885/tonton/mobileHero.jpg'
export const mobileMapCambie =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634682512/tonton/mobileMapCambie.jpg'
export const mobileMapNewWest =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634682512/tonton/mobileMapNewWest.jpg'
export const mobileMapBroadway =
  'https://d3333g6o4t76vd.cloudfront.net/mobileMap2.webp'
export const mobilePromo1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634682087/tonton/mobilePromo1.jpg'
export const mobilePromo2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634682088/tonton/mobilePromo2.jpg'
export const mobilePromo3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634682088/tonton/mobilePromo3.jpg'
export const mobilePromotionFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874390/samuraisushi/mobilePromotionFraer.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1637278785/tonton/orderPickupButton2.png'
export const promo1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634679229/tonton/promo1.jpg'
export const promo2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634679229/tonton/promo2.jpg'
export const promo3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1634679229/tonton/promo3.jpg'
export const promotionsFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874394/samuraisushi/promotionsFraser.png'
export const viewMenuButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1608578558/samuraisushi/viewMenuButton.png'

export { default as FraserMenu } from './Samurai_Fraser_Menu.pdf'
export { default as DavieMenu } from './Samurai_Davie_Menu.pdf'
